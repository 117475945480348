import queryString from 'querystring';

import useSWR, { mutate } from 'swr';

import { useInitialLoad } from '@src/hooks/useInitialLoad';
import { getSearchParam } from '@src/utils/searchParams';

import { genericFetcher } from '../fetcher';
import { makeRequest } from '../makeRequest';
import {
  IGetBalanceResponse,
  IGetPaginatedWithdrawalsPayload,
  IPagination,
  IRequestWithdrawalErrorResponse,
  IRequestWithdrawalPayload,
  Withdrawal,
  IApiResponse,
} from '../models/interfaces';

export const WITHDRAWALS_LIMIT = 10;

export const useWithdrawal = () => {
  const url = 'withdrawal/get-balance';
  const info = useSWR<IGetBalanceResponse>(url, genericFetcher(), {
    revalidateOnFocus: false,
  });

  const initialLoad = useInitialLoad(info.isValidating, url);

  if (info.error) {
    throw info.error;
  }

  return {
    ...info,
    initialLoad,
  };
};

export const useRequestedWithdrawals = ({ page }: IGetPaginatedWithdrawalsPayload) => {
  const params = queryString.stringify({ page: page - 1, limit: WITHDRAWALS_LIMIT });
  const url = `withdrawal?${params}`;

  const info = useSWR<IPagination<Withdrawal>>(url, genericFetcher(), {
    revalidateOnFocus: false,
  });

  const initialLoad = useInitialLoad(info.isValidating, url);

  if (info.error) {
    throw info.error;
  }

  return {
    ...info,
    initialLoad,
  };
};

export const requestWithdrawal = async (
  params: IRequestWithdrawalPayload,
): IApiResponse<Withdrawal, IRequestWithdrawalErrorResponse> => {
  const response = await makeRequest<Withdrawal | IRequestWithdrawalErrorResponse>({
    method: 'POST',
    url: `withdrawal/request-withdrawal/`,
    requestData: params,
  });

  const data = response.status === 200 ? (response.data as Withdrawal) : undefined;
  const errors =
    response.status === 400 ? (response.data as IRequestWithdrawalErrorResponse) : undefined;

  if (response.status === 200) {
    await mutate('withdrawal/get-balance');

    const page = +(getSearchParam('page') || 1) - 1;
    const mutateWithdrawalsParams = queryString.stringify({ page, limit: WITHDRAWALS_LIMIT });
    await mutate(`withdrawal?${mutateWithdrawalsParams}`);
  }

  return {
    data,
    errors,
  };
};
