import cx from 'classnames';
import { i18n } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';

import ArrowBottom from '@icons/arrow-button-bold.svg';

import s from './LanguageChange.module.sass';

type LanguageChangeProps = {
  className?: string;
};

const LanguageChange: React.FC<LanguageChangeProps> = ({ className }) => {
  const router = useRouter();
  const { pathname, asPath, query } = router;

  return (
    <div className={cx(s.languages, className)}>
      <div className={s.languagesContent}>
        <div className={s.language}>
          <div className={s.languageInner}>
            {i18n?.language === 'en' ? 'EN' : 'ZH'}
            <div className={s.languageIcon}>
              <ArrowBottom />
            </div>
          </div>
        </div>
        <button
          type="button"
          // onClick={() => i18n?.changeLanguage(i18n?.language === 'en' ? 'zh' : 'en')}
          onClick={() => {
            router.push({ pathname, query }, asPath, {
              locale: i18n?.language === 'en' ? 'zh' : 'en',
            });
          }}
          className={cx(s.language, s.notActive)}
        >
          <div className={s.languageInner}>{i18n?.language === 'en' ? 'ZH' : 'EN'}</div>
        </button>
      </div>
    </div>
  );
};

export default LanguageChange;
