import { useState, useEffect } from 'react';
import useSWR, { SWRResponse } from 'swr';

import { useInitialLoad } from '@src/hooks/useInitialLoad';
import { getIsAuthenticated } from '@src/utils/auth/getIsAuthenticated';
import { getGAClientId } from '@src/utils/getGAClientId';
import { genericFetcher } from 'src/api/fetcher';

import { makeRequest } from '../makeRequest';
import {
  IApiResponse,
  IChangeNameErrorResponse,
  IProfileInfoPayload,
  IConfirmChangeEmailErrorResponse,
  IConfirmChangePasswordErrorResponse,
  IConfirmEmailChangePayload,
  IConfirmEmailErrorResponse,
  IConfirmEmailPayload,
  IConfirmPasswordChangePayload,
  IConfirmPasswordResetPayload,
  IConfirmResetPasswordErrorResponse,
  IErrorResponse,
  IForgotPasswordErrorResponse,
  IForgotPasswordPayload,
  IRequestChangeEmailErrorResponse,
  IRequestEmailChangePayload,
  ISignupErrorResponse,
  ISignupPayload,
  ISuccessResponse,
  User,
} from '../models/interfaces';

if (typeof window !== 'undefined') {
  window.addEventListener('storage', (event) => {
    if (event.storageArea != localStorage) return;
    if (event.key === 'authenticated') {
      window.location.href = '/marketplace';
    }
  });
}

export const useFullProfile = (): Partial<SWRResponse<User, any>> & {
  initialLoad: boolean;
  loading: boolean;
} => {
  const authenticated = typeof window !== 'undefined' && getIsAuthenticated();
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(true);
  }, []);

  const url = pageLoaded && authenticated ? `users/` : null;

  const info = useSWR<User>(url, genericFetcher(), {
    revalidateOnFocus: false,
  });

  const initialLoad = useInitialLoad(info.isValidating || !pageLoaded);

  if (info.error) {
    throw info.error;
  }

  return {
    initialLoad,
    loading: !pageLoaded || (authenticated && !info.data && !info.error),
    ...info,
  };
};

export const confirmEmail = async ({
  code,
}: IConfirmEmailPayload): IApiResponse<ISuccessResponse, IConfirmEmailErrorResponse> => {
  const response = await makeRequest({
    method: 'POST',
    url: 'users/confirm-email/',
    requestData: { code },
  });

  const data = response.status === 200 ? (response.data as ISuccessResponse) : undefined;
  const errors =
    response.status === 400 ? (response.data as IConfirmEmailErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const registerUser = async (
  params: ISignupPayload,
): IApiResponse<ISuccessResponse, ISignupErrorResponse> => {
  const clientId = getGAClientId();

  const referrer = window.document.referrer.length > 0 ? window.document.referrer : undefined;
  const zionodesLandingSeoInfo: {
    landingURL: string;
    utmMedium: string;
    utmCampaign: string;
    utmSource: string;
    referrer: string;
    firstVisitDate: string;
    clientId: string;
  } = JSON.parse(localStorage.getItem('zionodesLandingSeoInfo') || '{}');

  const firstVisitInfo = {
    landingURL: zionodesLandingSeoInfo.landingURL,
    utmMedium: zionodesLandingSeoInfo.utmMedium,
    utmCampaign: zionodesLandingSeoInfo.utmCampaign,
    utmSource: zionodesLandingSeoInfo.utmSource,
    referrer: zionodesLandingSeoInfo.referrer,
    firstVisitDate: zionodesLandingSeoInfo.firstVisitDate,
    clientId: zionodesLandingSeoInfo.clientId,
  };

  // @ts-ignore
  const {
    utmMedium,
    utmCampaign,
    utmSource,
    landingURL,
  }: { utmMedium: string; utmCampaign: string; utmSource: string; landingURL: string } = window;

  const registrationInfo = {
    clientId,
    utmMedium,
    utmSource,
    utmCampaign,
    referrer,
    landingURL,
  };

  const response = await makeRequest({
    method: 'POST',
    url: 'users/signup/',
    requestData: { ...params, registrationInfo, firstVisitInfo },
  });

  const data = response.status === 200 ? (response.data as ISuccessResponse) : undefined;
  const errors = response.status === 400 ? (response.data as ISignupErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const requestPasswordChange = () =>
  makeRequest<ISuccessResponse | IErrorResponse>({
    method: 'POST',
    url: 'users/request-password-change/',
  });

export const confirmPasswordChange = async (
  params: IConfirmPasswordChangePayload,
): IApiResponse<ISuccessResponse, IConfirmChangePasswordErrorResponse> => {
  const response = await makeRequest({
    method: 'POST',
    url: 'users/confirm-new-password/',

    requestData: params,
  });

  const data = response.status === 200 ? (response.data as ISuccessResponse) : undefined;
  const errors =
    response.status === 400 ? (response.data as IConfirmChangePasswordErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const requestEmailChange = async (
  params: IRequestEmailChangePayload,
): IApiResponse<ISuccessResponse, IRequestChangeEmailErrorResponse> => {
  const response = await makeRequest({
    method: 'POST',
    url: 'users/request-email-change',
    requestData: params,
  });

  const data = response.status === 200 ? (response.data as ISuccessResponse) : undefined;
  const errors =
    response.status === 400 ? (response.data as IRequestChangeEmailErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const confirmEmailChange = async (
  params: IConfirmEmailChangePayload,
): IApiResponse<ISuccessResponse, IConfirmChangeEmailErrorResponse> => {
  const response = await makeRequest({
    method: 'POST',
    url: 'users/confirm-email-change',
    requestData: params,
  });

  const data = response.status === 200 ? (response.data as ISuccessResponse) : undefined;
  const errors =
    response.status === 400 ? (response.data as IConfirmChangeEmailErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const requestPasswordReset = async (
  params: IForgotPasswordPayload,
): IApiResponse<ISuccessResponse, IForgotPasswordErrorResponse> => {
  const response = await makeRequest({
    method: 'POST',
    url: 'users/request-reset-password',
    requestData: params,
  });

  const data = response.status === 200 ? (response.data as ISuccessResponse) : undefined;
  const errors =
    response.status === 400 ? (response.data as IForgotPasswordErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const confirmPasswordReset = async (
  params: IConfirmPasswordResetPayload,
): IApiResponse<ISuccessResponse, IConfirmResetPasswordErrorResponse> => {
  const response = await makeRequest({
    method: 'POST',
    url: 'users/confirm-reset-password/',

    requestData: params,
  });

  const data = response.status === 200 ? (response.data as ISuccessResponse) : undefined;
  const errors =
    response.status === 400 ? (response.data as IConfirmResetPasswordErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const changeProfileInfo = async (
  params: IProfileInfoPayload,
): IApiResponse<ISuccessResponse, IChangeNameErrorResponse> => {
  const response = await makeRequest({
    method: 'POST',
    url: 'users/change-profile-info',

    requestData: params,
  });

  const data = response.status === 200 ? (response.data as ISuccessResponse) : undefined;
  const errors = response.status === 400 ? (response.data as IChangeNameErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};
