import queryString from 'querystring';

import useSWR, { mutate } from 'swr';

import { useInitialLoad } from '@src/hooks/useInitialLoad';
import { getSearchParam } from '@src/utils/searchParams';

import { genericFetcher } from '../fetcher';
import { makeRequest } from '../makeRequest';
import {
  Address,
  ICreateAddressErrorResponse,
  IGetPaginatedAddressesPayload,
  ICreateAddressPayload,
  IPagination,
  IUpdateAddressErrorResponse,
  IUpdateAddressPayload,
  IApiResponse,
  Farm,
} from '../models/interfaces';

const ADDRESSES_LIMIT = 10;

const mutateAddresses = async () => {
  const page = getSearchParam('page') || 1;
  const params = queryString.stringify({ page: +page - 1, limit: ADDRESSES_LIMIT });

  await mutate(`address?${params}`);
};

export const postNewAddress = async (
  params: ICreateAddressPayload,
): IApiResponse<Address, ICreateAddressErrorResponse> => {
  const response = await makeRequest({
    method: 'POST',
    url: 'address/',
    requestData: params,
  });

  const data = response.status === 200 ? (response.data as Address) : undefined;
  const errors =
    response.status === 400 ? (response.data as ICreateAddressErrorResponse) : undefined;

  await mutateAddresses();

  return {
    data,
    errors,
  };
};

export const editAddress = async (
  id: string,
  params: IUpdateAddressPayload,
): IApiResponse<Address, IUpdateAddressErrorResponse> => {
  const response = await makeRequest({
    method: 'PUT',
    url: `address/${id}`,
    requestData: params,
  });

  const data = response.status === 200 ? (response.data as Address) : undefined;
  const errors =
    response.status === 400 ? (response.data as IUpdateAddressErrorResponse) : undefined;

  await mutateAddresses();

  return {
    data,
    errors,
  };
};

export const deleteAddress = async (id: string) => {
  await makeRequest({
    method: 'DELETE',
    url: `address/${id}`,
  });

  await mutateAddresses();
};

export const useAddresses = ({ page }: IGetPaginatedAddressesPayload) => {
  const params = queryString.stringify({ page: page - 1, limit: ADDRESSES_LIMIT });
  const url = `address?${params}`;

  const info = useSWR<IPagination<Address>>(url, genericFetcher(), {
    revalidateOnFocus: false,
  });

  const initialLoad = useInitialLoad(info.isValidating, url);

  if (info.error) {
    throw info.error;
  }

  return {
    ...info,
    initialLoad,
  };
};

export const useMiningFarms = () => {
  const url = `farm/list`;

  const info = useSWR<Farm[]>(url, genericFetcher(), {
    revalidateOnFocus: false,
  });

  const initialLoad = useInitialLoad(info.isValidating, url);

  if (info.error) {
    throw info.error;
  }

  return {
    ...info,
    initialLoad,
  };
};
