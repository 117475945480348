import cx from 'classnames';
import Link from 'next/link';
import React, { ReactNode } from 'react';

import s from './Button.module.sass';

// Props
type ButtonProps = {
  className?: string;
  theme?: string;
  type?: 'button' | 'submit' | 'reset';
  link?: string;
  as?: string;
  queryLink?: {
    pathname: string;
    query: {
      [key: string]: string | boolean;
    };
    hash: string;
  };
  external?: boolean;
  download?: boolean;
  icon?: ReactNode;
  disabled?: boolean;
  onClick?: (e: any) => void;
  children: ReactNode;
  buttonProps?: any;
};

const Button: React.FC<ButtonProps> = ({
  theme,
  type = 'button',
  icon,
  className,
  buttonProps,
  children,
  onClick,
  link,
  as,
  queryLink,
  external = false,
  download = false,
  disabled = false,
}) => {
  const classNames = cx(
    s.button,
    { [s.secondary]: theme && theme.includes('secondary') },
    { [s.ternary]: theme && theme.includes('ternary') },
    { [s.iconed]: icon },
    { [s.iconedCoin]: theme && theme.includes('iconedCoin') },
    { [s.iconedActive]: theme && theme.includes('iconedActive') },
    { [s.small]: theme && theme.includes('small') },
    { [s.middle]: theme && theme.includes('middle') },
    { [s.superSmall]: theme && theme.includes('superSmall') },
    { [s.tabs]: theme && theme.includes('tabs') },
    { [s.download]: theme && theme.includes('download') },
    { [s.social]: theme && theme.includes('social') },
    { [s.flexible]: theme && theme.includes('flexible') },
    className,
  );

  if (link || queryLink) {
    const linkInner = (
      <span className={s.content}>
        {icon && (
          <span className={s.circle}>
            <span className={s.inner}>{icon}</span>
          </span>
        )}
        <span className={s.text}>{children}</span>
      </span>
    );
    if (external) {
      return (
        <a href={link} target="_blank" rel="noreferrer noopener" className={classNames}>
          {linkInner}
        </a>
      );
    }
    if (download) {
      return (
        <a href={link} download className={classNames}>
          {linkInner}
        </a>
      );
    }
    if (queryLink) {
      return (
        <Link prefetch={false} href={queryLink}>
          <a href={queryLink.pathname} className={classNames}>
            {linkInner}
          </a>
        </Link>
      );
    }
    if (link && as) {
      return (
        <Link prefetch={false} href={link} as={as}>
          <a href={link} className={classNames}>
            {linkInner}
          </a>
        </Link>
      );
    }
    if (link) {
      return (
        <Link prefetch={false} href={link}>
          <a href={link} className={classNames}>
            {linkInner}
          </a>
        </Link>
      );
    }
  }

  return (
    <button
      className={classNames}
      type={type} // eslint-disable-line react/button-has-type
      onClick={onClick}
      {...buttonProps}
      disabled={disabled}
    >
      <span className={s.content}>
        {icon && (
          <span className={s.circle}>
            <span className={s.inner}>{icon}</span>
          </span>
        )}
        <span className={s.text}>{children}</span>
      </span>
    </button>
  );
};

export default Button;
