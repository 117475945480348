import queryString from 'querystring';

import { makeRequest } from '../makeRequest';
import {
  IApiResponse,
  IPaymentPayload,
  IPaymentErrorResponse,
  IMetamaskPaymentResponse,
  TBIPInvoice,
  IErrorResponse,
  IRestartPaymentResponse,
  IRegeneratePaymentPayload,
  ISuccessResponse,
  IMarkOrderAsPaidPayload,
} from '../models/interfaces';

import { mutateOrders } from './orders';

export const getMetaMaskPaymentData = async (
  params: IPaymentPayload,
): IApiResponse<IMetamaskPaymentResponse, IPaymentErrorResponse> => {
  const response = await makeRequest<IMetamaskPaymentResponse | IPaymentErrorResponse>({
    method: 'GET',
    url: `basket/payment-metamask?${queryString.stringify({ currency: params.currency })}`,
  });

  const data = response.status === 200 ? (response.data as IMetamaskPaymentResponse) : undefined;
  const errors = response.status === 400 ? (response.data as IPaymentErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const getPaymentData = async (
  params: IPaymentPayload,
): IApiResponse<TBIPInvoice, IPaymentErrorResponse> => {
  const response = await makeRequest<TBIPInvoice | IPaymentErrorResponse>({
    method: 'GET',
    url: `basket/payment?${queryString.stringify({ currency: params.currency })}`,
  });

  const data = response.status === 200 ? (response.data as TBIPInvoice) : undefined;
  const errors = response.status === 400 ? (response.data as IPaymentErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const regeneratePaymentData = async (
  params: IRegeneratePaymentPayload,
): IApiResponse<IRestartPaymentResponse, IPaymentErrorResponse> => {
  const response = await makeRequest<IRestartPaymentResponse | IErrorResponse>({
    method: 'POST',
    url: `basket/regenerate-payment-data`,
    requestData: params,
  });

  const data = response.status === 200 ? (response.data as IRestartPaymentResponse) : undefined;
  const errors = response.status === 400 ? (response.data as IErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const markAsPaid = async (
  params: IMarkOrderAsPaidPayload,
): IApiResponse<ISuccessResponse, IErrorResponse> => {
  const response = await makeRequest<ISuccessResponse | IErrorResponse>({
    method: 'POST',
    url: `order/mark-as-paid`,
    requestData: params,
  });

  if (response.status === 200) {
    await mutateOrders();
  }

  const data = response.status === 200 ? (response.data as ISuccessResponse) : undefined;
  const errors = response.status === 400 ? (response.data as IErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};
