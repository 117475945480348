export const minuteInMiliSeconds = 60 * 1000;
export const dayInMiliSeconds = 24 * 60 * 60 * 1000;
export const yearInMiliSeconds = 365 * 24 * 60 * 60 * 1000;

export const getIsAuthenticated = (): boolean => {
  const authenticationValidUntill = JSON.parse(
    localStorage.getItem('_local/authenticationValidUntill') || '0',
  );

  // For safety reasons we deactivate session 5 minutes earlier
  const safeAuthenticationValidUntill = authenticationValidUntill - 5 * minuteInMiliSeconds;

  const now = Date.now();

  return now < safeAuthenticationValidUntill;
};

export const deauthentify = () => {
  localStorage.setItem('_local/authenticationValidUntill', JSON.stringify(0));
  localStorage.setItem('_local/rememberer', JSON.stringify(false));
};

export const getSessionIsRemembered = () => {
  const sessionRemembered = JSON.parse(localStorage.getItem('_local/rememberer') || 'false');

  return sessionRemembered;
};

export const incrementSessionDuration = () => {
  const authenticated = getIsAuthenticated();

  if (authenticated) {
    const sessionRemembered = getSessionIsRemembered();

    const sessionDuration = sessionRemembered ? yearInMiliSeconds : dayInMiliSeconds;

    localStorage.setItem(
      '_local/authenticationValidUntill',
      JSON.stringify(Date.now() + sessionDuration),
    );
  }
};
