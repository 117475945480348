import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  Method,
} from 'axios';
import { i18n } from 'next-i18next';
import Router from 'next/router';

import { deauthentify, incrementSessionDuration } from '@src/utils/auth/getIsAuthenticated';
import { API_URL } from 'src/config';

export type RequestType = {
  method: Method;
  url: string;
  requestData?: object;
  options?: AxiosRequestConfig & { fullUrl?: boolean };
};

export const languageCode = {
  en: 'en',
  zh: 'zh-cn',
};

export async function makeRequest<T>({
  method,
  url,
  requestData,
  options,
}: RequestType): Promise<AxiosResponse<T>> {
  const headers = (options?.headers || {}) as AxiosRequestHeaders;
  headers['Accept-Language'] = languageCode[i18n?.language as keyof typeof languageCode];
  let data;
  try {
    data = await axios.request({
      url: options?.fullUrl ? url : `${API_URL}/${url}`,
      method,
      data: requestData,
      withCredentials: true,
      headers,
      ...options,
    });

    incrementSessionDuration();
  } catch (e) {
    const error = e as AxiosError;
    if (error.response && error.response.status === 401) {
      deauthentify();

      Router.push('/401');
      return error.response as AxiosResponse;
    }
    return error.response as AxiosResponse;
  }
  if (!data) {
    throw new Error(`no data for ${url} with ${requestData}`);
  }
  return data;
}
