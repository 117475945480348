import queryString from 'querystring';

import useSWR, { mutate } from 'swr';

import { useInitialLoad } from '@src/hooks/useInitialLoad';
import { filterNonNull } from '@src/utils/filterNonNull';
import { getSearchParam } from '@src/utils/searchParams';

import { genericFetcher } from '../fetcher';
import { makeRequest } from '../makeRequest';
import {
  IGetPaginatedOrdersPayload,
  IMarkAsReceivedErrorResponse,
  IMarkAsReceivedPayload,
  IMarkAsShippedErrorResponse,
  IMarkAsShippedPayload,
  IPagination,
  ISuccessResponse,
  Order,
  IApiResponse,
  IMarkAsReadyToBeShippedPayload,
  IMarkAsReadyToBeShippedErrorResponse,
} from '../models/interfaces';

const ORDERS_LIMIT = 15;

export const mutateOrders = async () => {
  const page = +(getSearchParam('page') || 1) - 1;
  const state = getSearchParam('state');
  const queryParams = queryString.stringify(
    filterNonNull({ page, limit: ORDERS_LIMIT, orderItemState: state?.length ? state : undefined }),
  );

  await mutate(`order?${queryParams}`);
};

export const useOrders = ({ page }: IGetPaginatedOrdersPayload) => {
  const state = getSearchParam('state');
  const params = queryString.stringify(
    filterNonNull({
      page: page - 1,
      limit: ORDERS_LIMIT,
      orderItemState: state?.length ? state : undefined,
    }),
  );
  const url = `order?${params}`;

  const info = useSWR<IPagination<Order>>(url, genericFetcher(), {
    revalidateOnFocus: false,
  });

  const initialLoad = useInitialLoad(info.isValidating, url);

  if (info.error) {
    throw info.error;
  }

  return {
    ...info,
    initialLoad,
  };
};

export const markAsShipped = async (
  params: IMarkAsShippedPayload,
): IApiResponse<ISuccessResponse, IMarkAsShippedErrorResponse> => {
  const { orderItemIds } = params;

  const response = await makeRequest({
    method: 'PUT',
    url: `orderitem/mark-as-shipped`,
    requestData: { orderItemIds },
  });

  const data = response.status === 200 ? (response.data as ISuccessResponse) : undefined;
  const errors =
    response.status === 400 ? (response.data as IMarkAsShippedErrorResponse) : undefined;

  if (response.status === 200) {
    await mutateOrders();
  }

  return {
    data,
    errors,
  };
};

export const markAsReceived = async (
  params: IMarkAsReceivedPayload,
): IApiResponse<ISuccessResponse, IMarkAsReceivedErrorResponse> => {
  const { orderItemIds } = params;

  const response = await makeRequest({
    method: 'PUT',
    url: `orderitem/mark-as-received`,
    requestData: { orderItemIds },
  });

  const data = response.status === 200 ? (response.data as ISuccessResponse) : undefined;
  const errors =
    response.status === 400 ? (response.data as IMarkAsReceivedErrorResponse) : undefined;

  if (response.status === 200) {
    await mutateOrders();
  }

  return {
    data,
    errors,
  };
};

export const markAsReadyToBeShipped = async (
  params: IMarkAsReadyToBeShippedPayload,
): IApiResponse<ISuccessResponse, IMarkAsReadyToBeShippedErrorResponse> => {
  const { orderItemIds } = params;

  const response = await makeRequest({
    method: 'PUT',
    url: `orderitem/mark-as-ready-to-be-shipped`,
    requestData: { orderItemIds },
  });

  const data = response.status === 200 ? (response.data as ISuccessResponse) : undefined;
  const errors =
    response.status === 400 ? (response.data as IMarkAsReadyToBeShippedErrorResponse) : undefined;

  if (response.status === 200) {
    await mutateOrders();
  }

  return {
    data,
    errors,
  };
};
