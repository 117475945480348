import queryString from 'querystring';

import useSWR, { mutate } from 'swr';

import { useInitialLoad } from '@src/hooks/useInitialLoad';
import { getSearchParam } from '@src/utils/searchParams';
import { genericFetcher } from 'src/api/fetcher';

import { makeRequest } from '../makeRequest';
import {
  ICreateItemErrorResponse,
  ICreateItemPayload,
  ICreateOfferErrorResponse,
  ICreateOfferPayload,
  ICreateOrderItemPayload,
  IErrorResponse,
  IGetPaginatedItemOffersPayload,
  IGetPaginatedOffersPayload,
  IGetPaginatedItemsPayload,
  Offer,
  OrderItem,
  IPagination,
  Item,
  IUpdateOfferErrorResponse,
  IUpdateOfferPayload,
  IApiResponse,
} from '../models/interfaces';

export const ITEMS_LIMIT = 10;
export const OFFERS_LIMIT = 10;

export const mutateOffers = async (itemId: string) => {
  const page = +(getSearchParam('page') || 1) - 1;
  const mutateOffersQueryParams = queryString.stringify({ page, limit: OFFERS_LIMIT });
  await mutate(`offer/item/${itemId}?${mutateOffersQueryParams}`);
};

export const useMarketplace = (arg: IGetPaginatedItemsPayload) => {
  const query = getSearchParam('query');
  const page = +(getSearchParam('page') || 1) - 1;

  const params = queryString.stringify({ ...arg, page, limit: ITEMS_LIMIT, filter: query });
  const url = `items/?${params}`;

  const info = useSWR<IPagination<Item>>(url, genericFetcher(), {
    revalidateOnFocus: false,
  });

  const initialLoad = useInitialLoad(info.isValidating, url);

  if (info.error) {
    throw info.error;
  }

  return {
    ...info,
    initialLoad,
  };
};

export const useMarketplaceItem = (
  shortcut: string,
  fallbackData: Item & { averageRating: number; ratingsCount: number },
) => {
  const info = useSWR<Item & { averageRating: number; ratingsCount: number }>(
    `items/${shortcut}`,
    genericFetcher(),
    {
      fallbackData,
      revalidateOnFocus: false,
    },
  );

  if (info.error) {
    throw info.error;
  }

  return info;
};

export const useMarketplaceItemOffers = (id: string, arg: IGetPaginatedItemOffersPayload) => {
  const params = queryString.stringify({ page: arg.page - 1, limit: OFFERS_LIMIT });
  const url = `offer/item/${id}?${params}`;

  const info = useSWR<
    IPagination<
      Offer & {
        avgOfferRating: number;
        avgSellerRating: number;
      }
    >
  >(url, genericFetcher(), {
    revalidateOnFocus: false,
  });

  const initialLoad = useInitialLoad(info.isValidating, url);

  if (info.error) {
    throw info.error;
  }

  return {
    ...info,
    initialLoad,
  };
};

export const useOffer = (id?: string) => {
  const url = id ? `offer/${id}` : null;
  const info = useSWR<Offer & { avgOfferRating: number; avgSellerRating: number }>(
    url,
    genericFetcher(),
    {
      revalidateOnFocus: false,
    },
  );

  if (info.error) {
    throw info.error;
  }

  const initialLoad = useInitialLoad(info.isValidating, url);

  return {
    ...info,
    initialLoad,
  };
};

export const useOffers = ({ page }: IGetPaginatedOffersPayload) => {
  const params = queryString.stringify({ page: page - 1, limit: OFFERS_LIMIT });

  const url = `offer?${params}`;
  const info = useSWR<IPagination<Offer>>(url, genericFetcher(), {
    revalidateOnFocus: false,
  });

  const initialLoad = useInitialLoad(info.isValidating, url);

  if (info.error) {
    throw info.error;
  }

  return {
    ...info,
    initialLoad,
  };
};

export const buyItems = async (params: ICreateOrderItemPayload) =>
  makeRequest<OrderItem | IErrorResponse>({
    method: 'POST',
    url: `orderitem`,
    requestData: params,
  });

export const addItem = async (
  params: ICreateItemPayload,
): IApiResponse<Item, ICreateItemErrorResponse> => {
  const formData = new FormData();
  const { images, ...itemData } = params;

  images.forEach((image) => {
    if (image) {
      formData.append('images', image);
    }
  });

  const dataJson = JSON.stringify(itemData);
  formData.append('data', dataJson);

  const response = await makeRequest<Item | ICreateItemErrorResponse>({
    method: 'POST',
    url: 'items',
    requestData: formData,
    options: {
      headers: { 'content-type': 'multipart/form-data' },
    },
  });

  const data = response.status === 200 ? (response.data as Item) : undefined;
  const errors = response.status === 400 ? (response.data as ICreateItemErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const postNewOffer = async (
  params: ICreateOfferPayload,
): IApiResponse<Offer, ICreateOfferErrorResponse> => {
  const response = await makeRequest({
    method: 'POST',
    url: 'offer/',
    requestData: params,
  });

  const data = response.status === 200 ? (response.data as Offer) : undefined;
  const errors = response.status === 400 ? (response.data as ICreateOfferErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const editOffer = async (
  id: string,
  params: IUpdateOfferPayload,
): IApiResponse<Offer, IUpdateOfferErrorResponse> => {
  const response = await makeRequest<Offer | IUpdateOfferErrorResponse>({
    method: 'PUT',
    url: `offer/${id}`,
    requestData: { ...params },
  });

  const data = response.status === 200 ? (response.data as Offer) : undefined;
  const errors = response.status === 400 ? (response.data as IUpdateOfferErrorResponse) : undefined;

  if (response.status === 200) {
    const page = +(getSearchParam('page') || 1) - 1;
    const queryParams = queryString.stringify({ page, limit: OFFERS_LIMIT });

    await mutate(`offer?${queryParams}`);
  }

  return {
    data,
    errors,
  };
};
