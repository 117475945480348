import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { i18n } from 'next-i18next';
import Router from 'next/router';

import { deauthentify, incrementSessionDuration } from '@src/utils/auth/getIsAuthenticated';
import { API_URL } from 'src/config';

import { RequestType, languageCode } from './makeRequest';

export async function makeSWRRequest<T>({
  method,
  url,
  requestData,
  options,
}: RequestType): Promise<AxiosResponse<T>> {
  const headers = (options?.headers || {}) as AxiosRequestHeaders;
  headers['Accept-Language'] = languageCode[i18n?.language as keyof typeof languageCode];
  let data;

  try {
    data = await axios.request({
      url: `${API_URL}/${url}`,
      method,
      data: requestData,
      withCredentials: true,
      headers,
      ...options,
    });

    incrementSessionDuration();
  } catch (e) {
    const error = e as AxiosError;
    if (error.response && error.response.status === 401) {
      deauthentify();

      Router.push('/401');
      return error.response as AxiosResponse;
    }
    return error.response as AxiosResponse;
  }

  if (!data) {
    throw new Error(`no data for ${url} with ${requestData}`);
  }
  return data;
}
