import queryString from 'querystring';

import useSWR, { mutate } from 'swr';

import { useInitialLoad } from '@src/hooks/useInitialLoad';

import { genericFetcher } from '../fetcher';
import { makeRequest } from '../makeRequest';
import {
  IAssignAddressPayload,
  Basket,
  OrderItem,
  IGetRatePayload,
  IApiResponse,
  IErrorResponse,
  TBIPRate,
  ISuccessResponse,
  IApplyDiscountCodePayload,
  IApplyDiscountCodeErrorResponse,
  Discount,
  IAssignFarmPayload,
  IAssignAleoAddressPayload,
  IAssignAleoAddressErrorResponse,
} from '../models/interfaces';

export const useTotalBasket = (params?: { skip?: boolean }) => {
  const url = params?.skip ? null : 'basket/total';
  const info = useSWR<Basket>(url, genericFetcher(), {
    revalidateOnFocus: false,
    revalidateOnMount: true,
  });

  const initialLoad = useInitialLoad(info.isValidating, url);

  return {
    ...info,
    initialLoad,
  };
};

export const useBasket = () => {
  const url = 'basket';
  const info = useSWR<Basket & { badItems: OrderItem[] }>(url, genericFetcher(), {
    revalidateOnFocus: false,
    revalidateOnMount: true,
  });

  const initialLoad = useInitialLoad(info.isValidating, url);

  if (info.error) {
    throw info.error;
  }

  return {
    ...info,
    initialLoad,
  };
};

export const useDiscountCodes = () => {
  const url = `discount/get-discounts-list`;
  const info = useSWR<Discount[]>(url, genericFetcher(), {
    revalidateOnFocus: false,
  });

  const initialLoad = useInitialLoad(info.isValidating, url);

  return {
    ...info,
    initialLoad,
  };
};

export const changeBasketAddress = async (data: IAssignAddressPayload) => {
  await makeRequest({
    method: 'POST',
    url: `basket/address`,
    requestData: data,
  });

  await mutate('basket');
};

export const changeBasketFarm = async (data: IAssignFarmPayload) => {
  await makeRequest({
    method: 'POST',
    url: `basket/address/farm`,
    requestData: data,
  });

  await mutate('basket');
};

export const getRates = async (
  params: IGetRatePayload,
): IApiResponse<TBIPRate | TBIPRate[], IErrorResponse> => {
  const ratesParams: { left?: string; right?: string } = {};

  if (params.left) {
    ratesParams.left = params.left;
  }

  if (params.right) {
    ratesParams.right = params.right;
  }

  const getRatesParams = queryString.stringify(ratesParams);

  const response = await makeRequest<TBIPRate | TBIPRate[] | IErrorResponse>({
    method: 'GET',
    url: `basket/get-rate?${getRatesParams}`,
    requestData: params,
  });

  const data = response.status === 200 ? (response.data as TBIPRate | TBIPRate[]) : undefined;
  const errors = response.status === 400 ? (response.data as IErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const applyDiscoundCode = async (
  params: IApplyDiscountCodePayload,
): IApiResponse<ISuccessResponse, IApplyDiscountCodeErrorResponse> => {
  const response = await makeRequest({
    method: 'POST',
    url: 'basket/assign-discount',

    requestData: params,
  });

  const data = response.status === 200 ? (response.data as ISuccessResponse) : undefined;
  const errors =
    response.status !== 200 ? (response.data as IApplyDiscountCodeErrorResponse) : undefined;

  if (response.status === 200) {
    await mutate('basket/total');
    await mutate('basket');
  }

  return {
    data,
    errors,
  };
};

export const assignAleoAddress = async (
  params: IAssignAleoAddressPayload,
): IApiResponse<ISuccessResponse, IAssignAleoAddressErrorResponse> => {
  const response = await makeRequest<ISuccessResponse | IAssignAleoAddressErrorResponse>({
    method: 'POST',
    url: `basket/aleo-address`,
    requestData: params,
  });

  await mutate('basket');

  const data = response.status === 200 ? (response.data as ISuccessResponse) : undefined;
  const errors =
    response.status === 400 ? (response.data as IAssignAleoAddressErrorResponse) : undefined;
  return {
    data,
    errors,
  };
};
