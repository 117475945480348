import useSWR from 'swr';

import { genericFetcher } from 'src/api/fetcher';

import { Department, IPagination } from '../models/interfaces';

export const useDepartmentShortcut = (departmentShortcut: string, fallbackData: Department) => {
  const info = useSWR(`departments/${departmentShortcut}`, genericFetcher(), {
    revalidateOnFocus: false,
    fallbackData,
  });

  if (info.error) {
    throw info.error;
  }

  return info;
};

export const useDepartments = () => {
  const info = useSWR<IPagination<Department>>('departments?page=0&limit=1000', genericFetcher(), {
    revalidateOnFocus: false,
  });

  if (info.error) {
    throw info.error;
  }

  return info.data;
};
