export enum SortType {
  ASC = 'ASC',
  DESC = 'DESC',
}
export enum UserRole {
  ADMIN = 'ADMIN',
  SELLER = 'SELLER',
  MANAGER = 'MANAGER',
  USER = 'USER',
  GHOST = 'GHOST',
}

export enum PaymentType {
  B2BINPAY = 'B2BINPAY',
  METAMASK = 'METAMASK',
}

export enum OrderItemState {
  PREPAID = 'PREPAID',
  PAID = 'PAID',
  PROCESSED = 'PROCESSED',
  DISPATCHED = 'DISPATCHED',
  RECEIVED = 'RECEIVED',
  SHIPPED = 'SHIPPED',
  UNDERPAID = 'UNDERPAID',
}

export enum WithdrawalState {
  REQUESTED = 'REQUESTED',
  SUCCESS = 'SUCCESS',
  INSUCCESS = 'INSUCCESS',
}

export enum CurrencyEnum {
  //Used
  BTC = 'BTC',
  ETH = 'ETH',
  DAI_ETH = 'DAI-ETH',
  USDT_ETH = 'USDT-ETH',
  USDT_BSC = 'BUSD-T-BSC',
  USDT_TRX = 'USDT-TRX',
  USDC_ETH = 'USDC-ETH',
  USDC_BSC = 'USDC-BSC',
  USDC_TRX = 'USDC-TRX',
}
