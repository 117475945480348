import { useState, useEffect } from 'react';

export const useInitialLoad = (isLoading: boolean, url?: string | null) => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (url) setIsInitialLoad(true);
  }, [url]);

  useEffect(() => {
    if (!isLoading) {
      setIsInitialLoad(isLoading);
    }
  }, [isLoading]);

  return isInitialLoad;
};
