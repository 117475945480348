import Head from 'next/head';
import React from 'react';

type MetaProps = {
  title?: string;
  description?: string;
  link?: string;
  image?: string;
  noIndex?: boolean;
  canonicalUrl?: string;
};

// {
//   props.css
//   && <link rel="stylesheet" href={`${props.css}`} />
// }
// {
//   props.image
//   && <meta name="twitter:image" content={`${props.image}`} />
// }
// {
//   props.canonical
//   && <link rel="canonical" href={`${props.canonical}`} />
// }
// {
//   props.js
//   && <script type="text/javascript" src={`${props.js}`} />
// }

function Meta({ title, description, link, image, noIndex, canonicalUrl }: MetaProps) {
  const pageTitle = title || 'Zionodes';

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name="og:title" property="og:title" content={pageTitle} />
      <meta name="twitter:title" content={pageTitle} />

      {description && (
        <>
          <meta name="description" content={description} />
          <meta name="og:description" property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </>
      )}

      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

      <meta property="og:type" content="website" />
      {noIndex && <meta name="robots" content="noindex" />}

      {image ? (
        <meta property="og:image" content={image} />
      ) : (
        <>
          <meta
            property="og:image"
            content="https://trade.zionodes.com/images/icon-512x512.png?v=3d5e8a6f8"
          />
          <meta property="og:image:width" content="512" />
          <meta property="og:image:height" content="512" />
        </>
      )}

      <meta property="og:site_name" content="Zionodes" />
      <meta property="og:url" content={`${link}`} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@zionodes" />
      <meta name="twitter:creator" content="@zionodes" />
      <link rel="icon" href="/images/favicon-32x32.png?v=3d5e8a6f8" type="image/png" />
      <link rel="apple-touch-icon" sizes="48x48" href="/images/icon-48x48.png?v=3d5e8a6f8" />
      <link rel="apple-touch-icon" sizes="72x72" href="/images/icon-72x72.png?v=3d5e8a6f8" />
      <link rel="apple-touch-icon" sizes="96x96" href="/images/icon-96x96.png?v=3d5e8a6f8" />
      <link rel="apple-touch-icon" sizes="144x144" href="/images/icon-144x144.png?v=3d5e8a6f8" />
      <link rel="apple-touch-icon" sizes="192x192" href="/images/icon-192x192.png?v=3d5e8a6f8" />
      <link rel="apple-touch-icon" sizes="256x256" href="/images/icon-256x256.png?v=3d5e8a6f8" />
      <link rel="apple-touch-icon" sizes="384x384" href="/images/icon-384x384.png?v=3d5e8a6f8" />
      <link rel="apple-touch-icon" sizes="512x512" href="/images/icon-512x512.png?v=3d5e8a6f8" />
    </Head>
  );
}

Meta.defaultProps = {
  link: '',
};

export default Meta;
