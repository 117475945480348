import classNames from 'classnames';
import Link from 'next/link';
import React, { ReactNode } from 'react';

import styles from './StyledCard.module.sass';

/* eslint-disable react/require-default-props */

// Props
type StyledCardProps = {
  theme?: string;
  link?: string;
  as?: string;
  children: ReactNode;
  className?: string;
  contentClassName?: string;
};

const StyledCard: React.FC<StyledCardProps> = ({
  theme,
  link,
  as,
  children,
  className,
  contentClassName,
}) => (
  <div
    className={classNames(
      styles.card,
      { [styles.linked]: link },
      { [styles.reverse]: theme && theme.includes('reverse') },
      { [styles.smallRadius]: theme && theme.includes('smallRadius') },
      { [styles.circled]: theme && theme.includes('circled') },
      { [styles.clickable]: theme && theme.includes('clickable') },
      { [styles.noPhone]: theme && theme.includes('noPhone') },
      className,
    )}
  >
    <div className={classNames(styles.content, contentClassName)}>
      {link ? (
        <Link prefetch={false} href={link} as={as}>
          <a className={styles.link} />
        </Link>
      ) : null}
      {children}
    </div>
  </div>
);

export default StyledCard;
