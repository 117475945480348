import { mutate } from 'swr';

import {
  dayInMiliSeconds,
  deauthentify,
  yearInMiliSeconds,
} from '@src/utils/auth/getIsAuthenticated';
import { getGAClientId } from '@src/utils/getGAClientId';

import { makeRequest } from '../makeRequest';
import { UserRole } from '../models/enums';
import {
  IApiResponse,
  IConfirm2FAPayload,
  IConfirm2FAErrorResponse,
  IConfirm2FAResponse,
  IErrorResponse,
  ILoginPayload,
  IRequest2FAResponse,
  ISuccessResponse,
  User,
  IRegenerateBackupCodesPayload,
  IRegenerateBackupCodesErrorResponse,
  IRegenerateBackupCodesResponse,
  IDeactivate2FAErrorResponse,
  IDeactivate2FAPayload,
  ILoginResponse,
  IConfirm2FALoginPayload,
  IConfirm2FALoginErrorResponse,
} from '../models/interfaces';

export const loginUser = async (
  params: ILoginPayload,
): IApiResponse<ILoginResponse, IErrorResponse> => {
  const response = await makeRequest<ILoginResponse | IErrorResponse>({
    method: 'POST',
    url: 'users/token/',
    requestData: params,
  });

  const data = response.status === 200 ? (response.data as ILoginResponse) : undefined;
  const errors = response.status === 400 ? (response.data as IErrorResponse) : undefined;

  if (
    data &&
    !(data as { is2FARequired: boolean }).is2FARequired &&
    ((data as User).role === UserRole.SELLER ||
      (data as User).role === UserRole.USER ||
      (data as User).role === UserRole.ADMIN)
  ) {
    const sessionDuration = params.remember ? yearInMiliSeconds : dayInMiliSeconds;
    localStorage.setItem(
      '_local/authenticationValidUntill',
      JSON.stringify(Date.now() + sessionDuration),
    );
    localStorage.setItem('_local/rememberer', JSON.stringify(params.remember || false));

    await mutate('users/', data);
  } else {
    deauthentify();
  }

  return {
    data,
    errors,
  };
};

export const activate2FA = async (): IApiResponse<IRequest2FAResponse, IErrorResponse> => {
  const response = await makeRequest<IRequest2FAResponse | IErrorResponse>({
    method: 'POST',
    url: `users/request-2fa`,
  });

  const data = response.status === 200 ? (response.data as IRequest2FAResponse) : undefined;
  const errors = response.status === 400 ? (response.data as IErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const confirmActivate2FA = async (
  params: IConfirm2FAPayload,
): IApiResponse<IConfirm2FAResponse, IConfirm2FAErrorResponse> => {
  const response = await makeRequest<IConfirm2FAResponse | IConfirm2FAErrorResponse>({
    method: 'POST',
    url: 'users/confirm-2fa',
    requestData: params,
  });

  const data = response.status === 200 ? (response.data as IConfirm2FAResponse) : undefined;
  const errors = response.status === 400 ? (response.data as IConfirm2FAErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const regenerateBackupCodes = async (
  params: IRegenerateBackupCodesPayload,
): IApiResponse<IRegenerateBackupCodesResponse, IRegenerateBackupCodesErrorResponse> => {
  const response = await makeRequest<
    IRegenerateBackupCodesResponse | IRegenerateBackupCodesErrorResponse
  >({
    method: 'POST',
    url: 'users/regenerate-2fa-codes',
    requestData: params,
  });

  const data =
    response.status === 200 ? (response.data as IRegenerateBackupCodesResponse) : undefined;
  const errors =
    response.status === 400 ? (response.data as IRegenerateBackupCodesErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const deactivate2FA = async (
  params: IDeactivate2FAPayload,
): IApiResponse<ISuccessResponse, IDeactivate2FAErrorResponse> => {
  const response = await makeRequest<ISuccessResponse | IDeactivate2FAErrorResponse>({
    method: 'POST',
    url: 'users/deactivate-2fa',
    requestData: params,
  });

  const data = response.status === 200 ? (response.data as ISuccessResponse) : undefined;
  const errors =
    response.status === 400 ? (response.data as IDeactivate2FAErrorResponse) : undefined;

  return {
    data,
    errors,
  };
};

export const confirm2FALogin = async (
  params: IConfirm2FALoginPayload,
): IApiResponse<User, IConfirm2FALoginErrorResponse> => {
  const clientId = getGAClientId();
  const response = await makeRequest<User | IConfirm2FALoginErrorResponse>({
    method: 'POST',
    url: 'users/2fa-login',
    requestData: { ...params, clientId },
  });

  const data = response.status === 200 ? (response.data as User) : undefined;
  const errors =
    response.status === 400 ? (response.data as IConfirm2FALoginErrorResponse) : undefined;

  if (data && ((data as User).role === UserRole.SELLER || (data as User).role === UserRole.USER)) {
    const sessionDuration = params.remember ? yearInMiliSeconds : dayInMiliSeconds;

    localStorage.setItem(
      '_local/authenticationValidUntill',
      JSON.stringify(Date.now() + sessionDuration),
    );
    localStorage.setItem('_local/rememberer', JSON.stringify(params.remember || false));

    await mutate('users/', data);
  } else {
    deauthentify();
  }

  return {
    data,
    errors,
  };
};

export const logoutUser = () =>
  makeRequest<ISuccessResponse | IErrorResponse>({
    method: 'POST',
    url: 'users/logout/',
  });
