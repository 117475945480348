import { RequestType } from './makeRequest';
import { makeSWRRequest } from './makeSWRRequest';

type GenericFetcherArgs = Omit<RequestType, 'method' | 'url'> & {
  method?: 'POST' | 'GET';
};

// TODO: this is unsecure, fix SSL on server-side and then remove following line
process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

export const genericFetcher = (arg?: GenericFetcherArgs): any =>
  function fetcher<Data>(url: string) {
    if (url) {
      return makeSWRRequest<Data>({ method: 'GET', url, ...arg }).then(
        (response) => response?.data,
      );
    }

    return undefined;
  };
