const urlMap = {
  production: 'https://api.trade.zionodes.com/api/v2',
  test: 'http://localhost:8000/api/v2',
  deploy: 'https://testtrade.zionodes.com/api/v2',
};

export const API_URL: string =
  urlMap[process.env.APP_ENV as keyof typeof urlMap] || 'http://localhost:8000/api/v2';

export const SITE_URL = 'https://trade.zionodes.com';

export const getURL = (path: string) => {
  return `${API_URL}/${path}`;
};
export const payment = {
  key: 'EuddZA1UFY9ED',
  secret: 'nW4OPcq6Q8s9VY',
};
